import { getCustomerForExternalClient } from "../utils/shellObjectService";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
import { DfpKey } from "@coworker/sharedlibrary";
import AppLogger from "../logger/appLogger";
import store from "../store/store";

const getIsellLoggingFriendlyResponse = response => {
	try {
		const { callingApplication } = store.getState().appController;
		const hostLoggingPreference = SalesCoworkerConfiguration.getPreference(DfpKey.ENABLE_WEBVIEWS_LOGGING_TO_ISELL);
		const isHostLoggingEnabled = hostLoggingPreference !== undefined && hostLoggingPreference.value === "true";

		if (callingApplication !== "ISELLCLIENT") return response;
		if (isHostLoggingEnabled) {
			return {
				payload: JSON.stringify(response),
				action: "RESPONSE",
				loggingLevel: "INFO",
				loggingMessage: "Response"
			};
		} else {
			return response;
		}
	} catch (err) {
		console.log(err);
	}
};

window.getData = function externalComponent(componentName, session) {
	const state = store.getState();
	const { sessionId } = state.appController;
	//=== sessionId need to fix the session missmatch

	AppLogger.info(componentName, "getData", {
		Session: session,
		ExternalClient: "iSell"
	});
	if (sessionId === session) {
		if (componentName === "customer") {
			let response = getCustomerForExternalClient();
			return JSON.stringify({ ...response, sessionId: sessionId });
		} else if (componentName === "import") {
			const { importData } = state.importController;
			importData.success = true;
			importData.sessionId = sessionId;
			importData.name = componentName;

			return JSON.stringify(importData);
		} else {
			return JSON.stringify({
				name: componentName,
				success: false,
				sessionId: sessionId,
				message: "component not available"
			});
		}
	} else {
		return JSON.stringify({
			name: componentName,
			success: false,
			sessionId: sessionId,
			message: "Invalid session"
		});
	}
};

window.sendToNative = function externalComponent(message) {
	const { componentName, data, action } = message;

	try {
		if (action === "CANCEL") {
			window.chrome.webview.postMessage("");
			return;
		}

		let response = data;
		if (componentName === "customer") {
			response = getCustomerForExternalClient({ bcmData: data });
		}

		const updresp = JSON.stringify(getIsellLoggingFriendlyResponse(response));
		window.chrome.webview.postMessage(updresp);
	} catch (error) {
		console.log(error);
		AppLogger.error(`Notify iSell client failed:${error.message}`, "webNotify");
	}
};

export function init() {
	console.log("Notify initialized");
}
